@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "frino";
  src: url("./fonts/GloriaHallelujah.ttf") format("truetype");
  font-display: block;
}

@layer base {
  h1 {
    @apply font-frino text-4xl sm:text-6xl;
  }

  h2 {
    @apply text-4xl font-frino;
  }

  h3 {
    @apply sm:text-4xl text-2xl font-frino;
  }

  h4 {
    @apply text-2xl sm:text-3xl font-frino;
  }

  span {
    @apply sm:text-xl text-base font-frino;
  }
}
body {
  background-color: rgb(224, 144, 171);
  overscroll-behavior: none;
  user-select: none;
  margin: 0;
  box-sizing: border-box;
  width: 100vw;
}

.bgPink {
  background-color: rgb(224, 144, 171);
}

* {
  cursor: none;
}

.frinoPlayer {
  transition: bottom 0.5s;
}

@keyframes upAndDown {
  from {
    bottom: 18px;
  }
  to {
    bottom: -2px;
  }
}

.upAndDown {
  animation: upAndDown infinite alternate 1s;
}

@keyframes scaleAnim {
  0% {
    transform: scale(1);
  }
  30% {
    transform: scale(1.1);
  }
  60% {
    transform: scale(1);
  }
}

.scaleAnim {
  animation: scaleAnim infinite alternate 1s;
}

@keyframes pulseShadow {
  0% {
    box-shadow: 0px 0px 5px #ffff00;
  }
  50% {
    box-shadow: 0px 0px 20px #ffff00;
  }
  100% {
    box-shadow: 0px 0px 5px #ffff00;
  }
}

.ebike {
  animation: ebike infinite linear 10s;
}

@keyframes ebike {
  0% {
    left: -300px;
    transform: rotateY(0deg);
  }
  40% {
    left: calc(100vw - 250px);
    transform: rotateY(0deg);
  }
  43% {
    left: calc(100vw - 250px);
    transform: rotateY(180deg);
  }
  80% {
    left: -300px;
    transform: rotateY(180deg);
  }
  100% {
    left: -300px;
    transform: rotateY(180deg);
  }
}

.pulseShadow {
  animation: pulseShadow infinite 1.2s;
}

.flipped {
  transform: rotateY(180deg);
}

@keyframes rotateAnim1 {
  from {
    transform: rotate(-3deg) translateY(10px);
  }
  to {
    transform: rotate(2deg) translateY(0px);
  }
}

@keyframes rotateAnim2 {
  from {
    transform: rotate(3deg) translateY(10px);
  }
  to {
    transform: rotate(-2deg) translateY(0px);
  }
}

@keyframes rotateAnim3 {
  0% {
    transform: rotate(3deg);
  }
  20% {
    transform: rotate(3deg);
  }
  30% {
    transform: rotate(-6deg);
  }
  70% {
    transform: rotate(-8deg);
  }
  100% {
    transform: rotate(3deg);
  }
}

@keyframes opEnNeer {
  0% {
    transform: rotateY(0deg);
    margin-left: 0px;
  }
  40% {
    transform: rotateY(0deg);
    margin-left: 270px;
  }
  50% {
    transform: rotateY(180deg);
    margin-left: 270px;
  }
  75% {
    transform: rotateY(180deg);
    margin-left: 0px;
  }
  85% {
    transform: rotateY(0deg);
    margin-left: 0px;
  }
}

.rotateAnim {
  transition: transform;
}

.rotateAnim1 {
  animation: rotateAnim1 alternate infinite 3.4s;
}

.rotateAnim2 {
  animation: rotateAnim2 alternate infinite 5.6s;
}

.rotateAnim3 {
  animation: rotateAnim3 alternate infinite 3s;
}

.opEnNeer {
  animation: opEnNeer infinite 5s;
}

@keyframes verrekijkerAnim {
  0% {
    transform: translate(50%, 100%);
  }
  50% {
    transform: translate(50%, 100%);
  }
  60% {
    transform: translate(0%, 0%);
  }
  70% {
    transform: translate(0%, 0%);
  }
}

.verrekijkerAnim {
  transition: transform;
  animation: verrekijkerAnim alternate infinite 4.6s;
}

.ebikeRijden {
  animation: ebike 10s linear infinite;
  transition: bottom 1s;
}

.spaced {
  letter-spacing: 0.15rem;
}
